import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import { LocationHero, LocationList } from '~/components/LocationPage';
import { PageContainer } from '~/components/.base/containers';
import './locations.scss';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';

const Locations = ({ data, location }) => {
  const [locationColumns, setLocationColumns] = useState([]);
  const [cmlStates, setCmlStates] = useState([]);

  useEffect(() => {
    if (data && data.allContentfulLocationPageFoodTruckSectionColumn) {
      setLocationColumns(
        data.allContentfulLocationPageFoodTruckSectionColumn.edges.map(item => item.node)
      );

      let cmlStatesArr = [];
      for (let column of data.allContentfulLocationPageFoodTruckSectionColumn.edges) {
        for (let state of column.node.foodTruckStates) {
          const stateCode = state.foodTrucks[0].states.split(',')[0];
          if (!cmlStatesArr.includes(stateCode)) {
            cmlStatesArr.push(stateCode);
          }
        }
      }
      setCmlStates(cmlStatesArr);
    }
  }, [data]);

  return (
    <>
      <MailingListPopup />
      <SEO title="Locations" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <PageContainer>
        <div className="locations">
          <LocationHero cmlStates={cmlStates} />
          <LocationList locationColumns={locationColumns} />
        </div>
      </PageContainer>
    </>
  );
};

export const query = graphql`
  query MyQuery {
    allContentfulLocationPageFoodTruckSectionColumn {
      edges {
        node {
          title
          foodTruckStates {
            title
            foodTrucks {
              title
              handle
              isATruck
              states
            }
          }
        }
      }
    }
  }
`;

export default Locations;
